<i18n>
  {
    "en": {
      "loadFund": "Load Fund",
      "amountInfo": "Please enter amount between 100 and 50000.",
      "amountToLoad": "Amount to load (Rs.)"
    },
    "np": {
       "loadFund": "लोड कोष",
       "amountInfo": "कृपया १०० र ५०००० बीचको रकम राख्नुहोस्।",
       "amountToLoad": "लोड गर्नुपर्ने रकम (रु.)"
     }
  }
</i18n>
<template lang="pug">
.container
  div(v-if="paymentModal")
    pin-confirm(:previewDetails='previewDetails' :pinVerification='verifyPin')
  .wallet-form-box.card
    .card-body
      router-link.btn.btn-close(to="/wallet")
        i.fa.fa-times
      h4 {{ $t("loadFund") }}
      form(ref="formRef", @submit.prevent)
        .form-group
          label {{ $t("amountToLoad") }}
          input.form-control(
            type="number",
            min="100",
            max="50000",
            v-model="fund.amount",
            required
          )
          small.text-muted {{ $t("amountInfo") }}
        .show-message(v-if="message") {{ message }}
        .box-bank-list(v-if="!loading")
          show-bank(
            v-if="banks",
            :showBanks="true",
            :banks="banks",
            :bankClicked="bankClicked"
          )
  loading-spinner(:loading="loading")
</template>

<script>
import ShowBank from "@/components/ShowBank";
import LoadingSpinner from "@/components/LoadingSpinner";
import api from "@/helpers/api";
import axios from "@/axios";
import PinConfirm from "@/components/PinConfirm";
import payment from "@/mixins/payment";
export default {
  name: "load-fund",
  components: { ShowBank, LoadingSpinner, PinConfirm },
  computed: {
    previewDetails() {
      return {
        "Total Amount to Load": this.fund.amount,
      };
    },
  },
  async mounted() {
    let allBanks = await api.banks();
    this.banks = allBanks.filter((b) => {
      return (
        b.bankName.indexOf("Kuraakani") === -1 && b.paymentType.indexOf("Prepaid") === -1
      );
    });

    this.loading = false;
  },
  mixins: [payment],
  data() {
    return {
      banks: "",
      fund: {
        amount: "",
        bank: "",
      },
      message: "",
      loading: true,
    };
  },
  methods: {
    verifyPin(verified) {
      this.fund.pin = verified;
      this.pinVerification(this.makePayment, verified);
    },
    bankClicked(bank) {
      if (this.fund.amount && this.$refs.formRef.reportValidity()) {
        this.fund.bank = bank;
        console.log(this.fund);
        if (this.pinVerified) {
          this.makePayment();
        } else {
          this.paymentModal = true;
        }
      } else {
        this.message = "Amount can't be less than 100 and greater than 10000.";
      }
    },
    makePayment() {
      this.loading = true;
      this.message = "";
      axios.post("api/v4/load_fund/payment", this.fund).then((res) => {
        console.log(res);
        if (res.data) {
          let dataRes = res.data;
          if (dataRes.status && dataRes.status == 1) {
            let thirdPartyDetails = dataRes.data;
            thirdPartyDetails.method = thirdPartyDetails.method.toLowerCase();
            let bankWindow = window.open("", "", "width=1000,height=1000");
            if (thirdPartyDetails.method == "get") {
              bankWindow.location.replace(thirdPartyDetails.url);
            } else {
              let form = bankWindow.document.createElement("form");
              form.style = "opacity: 0";
              form.method = thirdPartyDetails.method;
              form.action = thirdPartyDetails.url;
              if (thirdPartyDetails.params) {
                let body = thirdPartyDetails.params;
                Object.keys(thirdPartyDetails.params).forEach((key) => {
                  let inputEl = document.createElement("input");
                  inputEl.name = key;
                  inputEl.value = body[key];
                  form.appendChild(inputEl);
                });
                bankWindow.document.body.appendChild(form);
                form.submit();
              }
            }
            this.fund.amount = "";
            this.loading = false;
          } else {
            this.message = dataRes.message ? dataRes.message : "Something went wrong.";
            this.loading = false;
          }
        } else {
          this.loading = false;
          this.message = "Unable to process....";
        }
      });
    },
  },
};
</script>
